import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";

interface ISuccess {}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  success: {
    fontSize: "4rem",
    color: theme.palette.get("success")
  }
});

const Success: React.FC<ISuccess> = props => {
  const classes = buildClasses(styles);

  return (
    <div className={classes.success}>
      <FontAwesomeIcon icon={faCheck} />
    </div>
  );
};

export default Success;
