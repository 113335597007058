import React from "react";
import classNames from "classnames";
import { ITheme, IDefinitions } from "@nodeme/jss-react";
import { IProps as IPropsWithClasses, withClasses } from "@nodeme/jss-react";
import Block from "./Shared/Block";
import { TStatus } from "../Interfaces/IData";
import { IDisplayedLogs } from "./Timeclock";
import { Grid } from "@nodeme/grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-light-svg-icons/faPlay";
import { faStop } from "@fortawesome/pro-light-svg-icons/faStop";
import { faPause } from "@fortawesome/pro-light-svg-icons/faPause";
import { faHourglass } from "@fortawesome/pro-light-svg-icons/faHourglass";
import ILog from "@webcollector/client-sdk/lib/Interfaces/ILog";
import { faEdit } from "@fortawesome/pro-light-svg-icons";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  headline: {
    padding: "16px 0px 4px 8px",
    display: "block",
    color: theme.palette.get("grey"),
  },
  list: {
    listStyle: "none",
    padding: "0px",
  },
  listElement: {
    margin: "0px 0px 16px 0px",
  },
  time: {
    fontSize: "1.3rem",
  },
  container: {
    textAlign: "center",
  },
  borderRight: {
    borderRight: "1px solid " + theme.palette.get("grey"),
  },
  badge: {
    color: "#fff",
    fontSize: "0.rem",
    display: "inline-block",
    width: "32px",
    height: "32px",
    textAlign: "center",
    padding: "6px 0px 0px 3px",
    boxSizing: "border-box",
    borderRadius: "50px",
  },
  dangerBg: {
    backgroundColor: theme.palette.get("danger"),
  },
  warningBg: {
    backgroundColor: theme.palette.get("warning"),
  },
  successBg: {
    backgroundColor: theme.palette.get("success"),
  },
  primaryBg: {
    backgroundColor: theme.palette.get("primary"),
  },
  danger: {
    color: theme.palette.get("danger"),
  },
  warning: {
    color: theme.palette.get("warning"),
    fontSize: "1.1rem",
  },
  success: {
    color: theme.palette.get("success"),
    fontSize: "1.1rem",
  },
  primary: {
    color: theme.palette.get("primary"),
    fontSize: "1.1rem",
  },
  context: {
    margin: "4px 0px 0px 0px",
    color: theme.palette.get("grey"),
    fontStyle: "italic",
  },
});

interface IProps {
  activeLog: ILog[];
  logs: IDisplayedLogs[];
  errors: {};
  status: TStatus;
}

interface IState {}

const days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

class Timeclock extends React.Component<IPropsWithClasses<IProps>, IState> {
  getIcon(type: string) {
    const { classes } = this.props;
    switch (type) {
      case "A":
        return (
          <span className={classNames([classes.badge, classes.primaryBg])}>
            <FontAwesomeIcon icon={faPlay} />
          </span>
        );
      case "E":
        return (
          <span
            className={classNames([classes.badge, classes.dangerBg])}
            style={{ paddingLeft: "0px" }}
          >
            <FontAwesomeIcon icon={faStop} />
          </span>
        );
      case "PA":
        return (
          <span
            className={classNames([classes.badge, classes.warningBg])}
            style={{ paddingLeft: "0px" }}
          >
            <FontAwesomeIcon icon={faPause} />
          </span>
        );
      case "PE":
        return (
          <span className={classNames([classes.badge, classes.warningBg])}>
            <FontAwesomeIcon icon={faPlay} />
          </span>
        );
      case "KA":
        return (
          <span className={classNames([classes.badge, classes.successBg])}>
            <FontAwesomeIcon icon={faEdit} />
          </span>
        );
      default:
        return null;
    }
  }
  render() {
    const { classes } = this.props;

    return (
      <Block>
        <ul className={classes.list}>
          {this.props.activeLog.map((items) => {
            const body = JSON.parse(items.body || "");
            const date = new Date(items.createdAt || "");
            return (
              <li
                key={items.createdAt + "-active"}
                className={classes.listElement}
              >
                <Grid>
                  <Grid item xs={"50px"}>
                    {this.getIcon(body.name || "")}{" "}
                  </Grid>
                  <Grid item xs={"calc(100% - 50px)"}>
                    <span className={classes.time}>
                      {date.getHours() < 10 && "0"}
                      {date.getHours()}:{date.getMinutes() < 10 && "0"}
                      {date.getMinutes()}
                    </span>
                    <div className={classes.context}>
                      {(body.name === "KA" && body.id + " - " + body.comment) ||
                        (body.name === "A" && "Start") ||
                        (body.name === "PA" && "Pause") ||
                        (body.name === "PE" && "Pause Ende") ||
                        (body.name === "E" && "Stop")}
                    </div>
                  </Grid>
                </Grid>
              </li>
            );
          })}
          {this.props.logs
            .filter((log) => log.end !== null)
            .map((log: IDisplayedLogs, index) => {
              const start = new Date(log.start || "");
              const stop = new Date(log.end || "");
              const day = days[start.getDay()];
              const pause = log.pauses.reduce((accu, value) => {
                if (value.start && value.end)
                  return accu + +new Date(value.end) - +new Date(value.start);
                return accu;
              }, 0);
              const worked = Math.floor((+stop - +start - pause) / 1000);
              const pauseInSeconds = Math.floor(pause / 1000);
              const pauseHoures = Math.floor(pauseInSeconds / 3600);
              const pauseMinutes = Math.floor((pauseInSeconds % 3600) / 60);
              const workedHoures = Math.floor(worked / 3600);
              const workedMinutes = Math.floor((worked % 3600) / 60);

              return (
                <li key={log.start} className={classes.listElement}>
                  <Grid>
                    <Grid item>
                      <span
                        style={{ paddingTop: !index ? 0 : "auto" }}
                        className={classes.headline}
                      >
                        {day} - {start.getDate()}.{start.getMonth() + 1}.
                        {start.getFullYear()}
                      </span>
                    </Grid>
                    <Grid item xs={10 / 4}>
                      <div
                        className={classNames([
                          classes.container,
                          classes.borderRight,
                        ])}
                      >
                        <FontAwesomeIcon
                          className={classes.primary}
                          icon={faPlay}
                        />
                        <br />
                        <span className={classes.time}>
                          {start.getHours() < 10 && "0"}
                          {start.getHours()}:{start.getMinutes() < 10 && "0"}
                          {start.getMinutes()}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={10 / 4}>
                      <div
                        className={classNames([
                          classes.container,
                          classes.borderRight,
                        ])}
                      >
                        <FontAwesomeIcon
                          className={classes.danger}
                          icon={faStop}
                        />
                        <br />
                        <span className={classes.time}>
                          {stop.getHours() < 10 && "0"}
                          {stop.getHours()}:{stop.getMinutes() < 10 && "0"}
                          {stop.getMinutes()}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={10 / 4}>
                      <div
                        className={classNames([
                          classes.container,
                          classes.borderRight,
                        ])}
                      >
                        <FontAwesomeIcon
                          className={classes.warning}
                          icon={faPause}
                        />
                        <br />
                        <span className={classes.time}>
                          {pauseHoures < 10 && "0"}
                          {pauseHoures}:{pauseMinutes < 10 && "0"}
                          {pauseMinutes}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={10 / 4}>
                      <div className={classes.container}>
                        <FontAwesomeIcon
                          className={classes.success}
                          icon={faHourglass}
                        />
                        <br />
                        <span className={classes.time}>
                          {workedHoures < 10 && "0"}
                          {workedHoures}:{workedMinutes < 10 && "0"}
                          {workedMinutes}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </li>
              );
            })}
        </ul>
      </Block>
    );
  }
}

export default withClasses(styles, Timeclock);
