import IError from "./IError";

const DATA_SUCCESS: TStatus = "DATA_STATUS_SUCCESS";
const DATA_OK: TStatus = "DATA_STATUS_OK";
const DATA_OK_NOT_COMPLETE: TStatus = "DATA_STATUS_NOT_COMPLETE";
const DATA_INIT: TStatus = "DATA_STATUS_INIT";
const DATA_NOT_FETCHED: TStatus = "DATA_STATUS_NOT_FETCHED";
const DATA_PENDING: TStatus = "DATA_STATUS_PENDING";
const DATA_DELETED: TStatus = "DATA_STATUS_DELETED";
const DATA_ERROR: TStatus = "DATA_STATUS_ERROR";
const DATA_LIMIT_ERROR: TStatus = "DATA_STATUS_LIMIT_ERROR";

export type TStatus =
  | "DATA_STATUS_SUCCESS"
  | "DATA_STATUS_DELETED"
  | "DATA_STATUS_OK"
  | "DATA_STATUS_NOT_FETCHED"
  | "DATA_STATUS_PENDING"
  | "DATA_STATUS_INIT"
  | "DATA_STATUS_ERROR"
  | "DATA_STATUS_NOT_COMPLETE"
  | "DATA_STATUS_LIMIT_ERROR";

export default interface IData<T> {
  data: T;
  error?: IError;
  next?: string;
  pendingRequestId?: string; //used to abort promises if there is a newer request
  status: TStatus;
}

export {
  DATA_SUCCESS,
  DATA_OK,
  DATA_NOT_FETCHED,
  DATA_PENDING,
  DATA_ERROR,
  DATA_INIT,
  DATA_OK_NOT_COMPLETE,
  DATA_DELETED,
  DATA_LIMIT_ERROR,
};
