import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";
import Headline from "../Headline";

interface IError {}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  icon: {
    fontSize: "3.2rem"
  }
});

const Error: React.FC<IError> = props => {
  const classes = buildClasses(styles);

  return (
    <div>
      <Headline variant="h1">
        <FontAwesomeIcon
          className={classes.icon}
          icon={faExclamationTriangle}
        />{" "}
        Ups...
      </Headline>
      <p>Ein unbekannter Fehler ist aufgetreten.</p>
    </div>
  );
};

export default Error;
