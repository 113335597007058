import React from "react";
import { Grid } from "@nodeme/grid-react";
import logo from "../../Assets/images/logoWebcollector.svg";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

interface ILogo {
  color?: string;
  size?: number;
  labelSize?: number;
  noLabel?: boolean;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  logo: (props: ILogo) => ({
    display: "block",
    maxWidth: "100%",
    height: props.size,
  }),
  label: (props: ILogo) => ({
    display: "block",
    fontSize:
      typeof props.labelSize !== "undefined"
        ? `${props.labelSize}rem`
        : "1.7rem",
    marginLeft: "8px",
    color: props.color || "initial",
    lineHeight: typeof props.size !== "undefined" ? `${props.size}px` : "60px",
  }),
});

const Logo: React.FC<ILogo> = (props) => {
  const classes = buildClasses(styles, props);

  return (
    <Grid spacing={0}>
      <Grid
        item
        xs={typeof props.size !== "undefined" ? `${props.size}px` : "60px"}
      >
        <img src={logo} className={classes.logo} alt="webcollector-logo" />
      </Grid>
      {!props.noLabel && (
        <Grid item xs="rest">
          <div>
            <span className={classes.label}>Webcollector</span>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default Logo;
