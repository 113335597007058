import React from "react";
import { Grid } from "@nodeme/grid-react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";
import { Authenticator } from "@webcollector/authenticator-react";

import Logo from "./Shared/Logo";
import IUser from "@webcollector/client-sdk/lib/Interfaces/IUser";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  row: {
    height: "100vh",
    backgroundColor: "#000",
  },
});

interface IAuthenticationProps {
  setUser: (user: IUser | null) => void;
  hasSession: boolean;
}

const Authentication: React.FC<IAuthenticationProps> = (props) => {
  //const { i18n, language } = props;
  const classes = buildClasses(styles, props);
  const { hasSession } = props;
  return (
    <div style={{ display: hasSession ? "none" : "block" }}>
      <Grid
        override={{ row: classes.row }}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
          <Logo color="#fff" size={130} />
        </Grid>
        <Grid item xs={10} sm={10} md={5} lg={4} xl={"400px"}>
          <Authenticator lang="de" setUser={props.setUser} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Authentication;
