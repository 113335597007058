import React from "react";
import classnames from "classnames";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

interface IHeadline {
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subheading";
  inactive?: boolean;
  className?: string;
  children: any;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  headline: {
    fontFamily: '"Noto Sans", sans-serif',
  },
  h1: {
    fontSize: "2.5rem",
  },
  h2: {
    fontSize: "2rem",
  },
  h3: {
    fontSize: "1.5rem",
  },
  h4: {
    fontSize: "1.3rem",
  },
  h5: {
    fontSize: "1.15rem",
  },
  subheading: (props: IHeadline) => ({
    letterSpacing: "0.1rem",
    color: theme.palette.get(props.inactive ? "default" : "primary"),
    fontSize: "0.9rem",
    fontWeight: "700",
    textTransform: "uppercase",
  }),
});

const Headline: React.FC<IHeadline> = (props) => {
  const classes = buildClasses(styles, props);

  switch (props.variant) {
    case "subheading":
      return (
        <h6
          className={classnames([
            classes.headline,
            classes.subheading,
            props.className,
          ])}
        >
          {props.children}
        </h6>
      );
    case "h5":
      return (
        <h5
          className={classnames([
            classes.headline,
            classes.h5,
            props.className,
          ])}
        >
          {props.children}
        </h5>
      );
    case "h4":
      return (
        <h4
          className={classnames([
            classes.headline,
            classes.h4,
            props.className,
          ])}
        >
          {props.children}
        </h4>
      );
    case "h3":
      return (
        <h3
          className={classnames([
            classes.headline,
            classes.h3,
            props.className,
          ])}
        >
          {props.children}
        </h3>
      );
    case "h2":
      return (
        <h2
          className={classnames([
            classes.headline,
            classes.h2,
            props.className,
          ])}
        >
          {props.children}
        </h2>
      );
    case "h1":
    default:
      return (
        <h1
          className={classnames([
            classes.headline,
            classes.h1,
            props.className,
          ])}
        >
          {props.children}
        </h1>
      );
  }
};

export default Headline;
