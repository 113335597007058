import React from "react";
import classnames from "classnames";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

interface IOverlay {
  open: boolean;
  color?: string;
  className?: string;
  children: any;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  overlay: {
    position: "absolute",
    minHeight: "100%",
    width: "100%",
    zIndex: 5
  }
});

const Overlay: React.FC<IOverlay> = props => {
  const classes = buildClasses(styles, props);
  if (!props.open) return null;
  const style: { [key: string]: any } = {};
  if (props.color) style.backgroundColor = props.color;
  return (
    <div
      className={classnames([classes.overlay, props.className])}
      style={style}
    >
      {props.children}
    </div>
  );
};

export default Overlay;
