import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoNotEnter } from "@fortawesome/pro-light-svg-icons/faDoNotEnter";

interface IDelete {}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  center: { textAlign: "center" },
  danger: {
    fontSize: "4rem",
    color: theme.palette.get("danger")
  }
});

const Success: React.FC<IDelete> = props => {
  const classes = buildClasses(styles);

  return (
    <div className={classes.center}>
      <FontAwesomeIcon className={classes.danger} icon={faDoNotEnter} />
      <p>Sie haben ihr Limit bereits ausgeschöpft.</p>
    </div>
  );
};

export default Success;
