import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";

interface IDelete {}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  danger: {
    fontSize: "4rem",
    color: theme.palette.get("danger")
  }
});

const Success: React.FC<IDelete> = props => {
  const classes = buildClasses(styles);

  return (
    <div className={classes.danger}>
      <FontAwesomeIcon icon={faTrash} />
    </div>
  );
};

export default Success;
