import React, { useState } from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";
import Block from "./Shared/Block";
import { Form, Text } from "@nodeme/forms-react";
import Numbers from "@nodeme/forms-react/lib/Components/Number";
import Button from "@nodeme/forms-react/lib/Components/Button";
import { Grid } from "@nodeme/grid-react";
import {
  DATA_ERROR,
  DATA_OK,
  DATA_PENDING,
  TStatus,
} from "../Interfaces/IData";
import StateHandler from "./Shared/StateHandler";
import Task from "@webcollector/client-sdk/lib/Utility/Task";
import IUser from "@webcollector/client-sdk/lib/Interfaces/IUser";
import Overlay from "./Shared/Overlay";

interface IProps {
  user: IUser;
  enabled: boolean;
}

interface IState {
  id?: number;
  comment?: string;
  status: TStatus;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  headline: {
    color: theme.palette.get("primary"),
    fontWeight: "600",
    letterSpacing: "1px",
  },
  overlay: {
    padding: "16px",
  },
});

const addTaskLog = async (
  user: IUser,
  state: IState,
  setState: (state: IState) => void
) => {
  if (typeof state.id !== "undefined") {
    setState({ ...state, status: DATA_PENDING });
    try {
      await Task.addLog(user.usagePlan, state.id, state.comment);
      setState({
        ...state,
        id: undefined,
        comment: undefined,
        status: DATA_OK,
      });
    } catch (error) {
      setState({ ...state, status: DATA_ERROR });
    }
  }
};

const Tasklog: React.FC<IProps> = (props) => {
  const classes = buildClasses(styles, props);
  const [state, setState] = useState<IState>({
    status: DATA_OK,
  });
  return (
    <StateHandler status={state.status} errors={{}}>
      <Overlay open={!props.enabled} color="rgba(255, 255, 255, 1)">
        <div className={classes.overlay}>
          <h2>Arbeitszeit starten</h2>
          <p>
            Kostenstellen können nur innerhalb aktiver Arbeitszeiten hinzugefügt
            werden.
          </p>
        </div>
      </Overlay>
      <Block>
        <h4 className={classes.headline}>KOSTENSTELLE</h4>
        <Form
          spacing={16}
          root={{ left: 0, right: 0 }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Numbers
            item
            inline
            xs={5}
            label="ID"
            value={state.id}
            onChange={(value) => {
              setState({ ...state, id: value });
            }}
          />
          <Text
            item
            inline
            label="Kommentar"
            rows={4}
            value={state.comment}
            onChange={(value) => {
              setState({ ...state, comment: value });
            }}
          />
          <Grid justify="flex-end">
            <Button
              item
              xs="120px"
              success
              onClick={() => {
                addTaskLog(props.user, state, setState);
              }}
            >
              Hinzufügen
            </Button>
          </Grid>
        </Form>
      </Block>
    </StateHandler>
  );
};

export default Tasklog;
