import React, { useState } from "react";
import "./Assets/styles/app.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Authentication from "./Component/Authentication";
import PendingPage from "./Component/Shared/PendingPage";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";
import Timeclock from "./Component/Timeclock";
import IUser from "@webcollector/client-sdk/lib/Interfaces/IUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/pro-light-svg-icons";
import SDK from "@webcollector/client-sdk";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  content: {
    padding: "34px 8px 34px 8px",
    [theme.breakpoints.up("md")]: { padding: "64px 32px 64px" },
  },
  header: {
    padding: "8px 16px",
    backgroundColor: "#000",
    color: "#fff",
    fontSize: "1.5rem",
    textAlign: "right",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    cursor: "pointer",
  },
});
interface IProps {}
interface IState {
  session: boolean;
  inited: boolean;
  user: IUser | null;
}
const App: React.FC<IProps> = (props) => {
  const classes = buildClasses(styles);
  const [state, setState] = useState<IState>({
    session: false,
    inited: false,
    user: null,
  });
  return (
    <div className="App">
      {!state.inited && <PendingPage text="Seite wird geladen" />}
      <div>
        <Authentication
          setUser={(user) =>
            setState({ inited: true, session: Boolean(user), user })
          }
          hasSession={state.session}
        />
        {state.inited && state.session && state.user && (
          <Router>
            <header className={classes.header}>
              <FontAwesomeIcon
                className={classes.link}
                onClick={async (e) => {
                  e.preventDefault();
                  await SDK.logout();
                  window.location.reload();
                }}
                icon={faSignOut}
              />
            </header>
            <main className={classes.content}>
              <Switch>
                <Route path="/" exact>
                  <Timeclock user={state.user} />
                </Route>
              </Switch>
            </main>
          </Router>
        )}
      </div>
    </div>
  );
};

export default App;
