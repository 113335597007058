import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";
import { Grid } from "@nodeme/grid-react";
import Logo from "./Logo";

interface IPendingPage {
  text: string;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  row: {
    height: "100vh",
    backgroundColor: "#000",
  },
  wrapper: {
    textAlign: "center",
  },
  text: {
    color: "#fff",
  },
});

const PendingButton: React.FC<IPendingPage> = (props) => {
  const classes = buildClasses(styles, props);
  return (
    <Grid override={{ row: classes.row }} alignItems="center" justify="center">
      <Grid item xs="130px">
        <div className={classes.wrapper}>
          <Logo color="#fff" size={130} noLabel />
          <p className={classes.text}>{props.text}</p>
        </div>
      </Grid>
    </Grid>
  );
};

export default PendingButton;
