import React, { ReactNode, Component } from "react";
import { ITheme, IDefinitions, withClasses } from "@nodeme/jss-react";
import {
  TStatus,
  DATA_PENDING,
  DATA_ERROR,
  DATA_INIT,
  DATA_SUCCESS,
  DATA_DELETED,
  DATA_LIMIT_ERROR,
  DATA_OK,
} from "../../../Interfaces/IData";
import Overlay from "../Overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import IPropsWithClasses from "@nodeme/jss-react/lib/Interfaces/IProps";
import Error from "./Error";
import Success from "./Success";
import Delete from "./Delete";
import LimitError from "./LimitError";

interface IProps {
  status: TStatus;
  errors: { [key: string]: any } | null;
  children: ReactNode;
  noSuccess?: boolean;
}
interface IState {
  success: boolean;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  stateHandler: {
    position: "relative",
  },
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pending: {
    fontSize: "4rem",
    color: theme.palette.get("primary"),
  },
});

class StateHandler extends Component<IPropsWithClasses<IProps>, IState> {
  MOUNTED = true;
  constructor(props: IPropsWithClasses<IProps>) {
    super(props);
    this.state = {
      success: false,
    };
  }

  componentWillUnmount() {
    this.MOUNTED = false;
  }

  componentDidUpdate(prevProps: IPropsWithClasses<IProps>) {
    const prevStatus = prevProps.status;
    const { status, noSuccess } = this.props;
    if (!noSuccess && prevStatus === DATA_PENDING && status === DATA_OK) {
      this.setState({ ...this.state, success: true });
      setTimeout(() => {
        if (this.MOUNTED) this.setState({ ...this.state, success: false });
      }, 1000);
    }
  }

  render() {
    const props = this.props;
    const { classes, status, errors } = props;
    const { success } = this.state;

    const hasUnkownError =
      status === DATA_ERROR && !Object.keys(errors || {}).length;

    return (
      <div className={classes.stateHandler}>
        <Overlay
          open={status === DATA_INIT || status === DATA_PENDING}
          className={classes.overlay}
        >
          <div className={classes.pending}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </div>
        </Overlay>
        <Overlay
          open={status === DATA_SUCCESS || success}
          className={classes.overlay}
        >
          <Success />
        </Overlay>
        <Overlay open={status === DATA_DELETED} className={classes.overlay}>
          <Delete />
        </Overlay>
        <Overlay open={status === DATA_LIMIT_ERROR} className={classes.overlay}>
          <LimitError />
        </Overlay>
        <Overlay className={classes.overlay} open={hasUnkownError}>
          <Error />
        </Overlay>
        {props.children}
      </div>
    );
  }
}

export default withClasses<IProps, IState>(styles, StateHandler);
