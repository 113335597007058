import React from "react";
import { Grid } from "@nodeme/grid-react";
import { ITheme, IDefinitions } from "@nodeme/jss-react";
import { IProps as IPropsWithClasses, withClasses } from "@nodeme/jss-react";
import Block from "./Shared/Block";
import { Form } from "@nodeme/forms-react";
import Button from "@nodeme/forms-react/lib/Components/Button";
import TimeclockLib from "@webcollector/client-sdk/lib/Utility/Timeclock";
import IUser from "@webcollector/client-sdk/lib/Interfaces/IUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-light-svg-icons/faPlay";
import {
  DATA_ERROR,
  DATA_INIT,
  DATA_OK,
  DATA_PENDING,
  TStatus,
} from "../Interfaces/IData";
import StateHandler from "./Shared/StateHandler";
import { faPause } from "@fortawesome/pro-light-svg-icons/faPause";
import { faStop } from "@fortawesome/pro-light-svg-icons/faStop";
import ILog from "@webcollector/client-sdk/lib/Interfaces/ILog";
import Log from "@webcollector/client-sdk/lib/Utility/Log";
import LogComp from "./Log";
import classNames from "classnames";
import Tasklog from "./Tasklog";
const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  display: {
    textAlign: "center",
    fontSize: "4rem",
  },
  list: {
    listStyle: "none",
  },
  pause: {
    color: theme.palette.get("warning"),
  },
});

interface IProps {
  user: IUser;
}

export interface IDisplayedLogs {
  start: string | null;
  end: string | null;
  pauses: { start: string; end: string | null }[];
}

interface IState {
  logs: ILog[];
  activeLog: ILog[];
  displayedLogs: IDisplayedLogs[];
  logStatus: TStatus;
  status: TStatus;
  start: number | null;
  worked: number | null;
  pauses: { start: number; end: number | null }[];
  confirmClose: { number: string; comment: string } | null;
  clockState: "A" | "P" | "E";
  errors: {};
}

class Timeclock extends React.Component<IPropsWithClasses<IProps>, IState> {
  MOUNTED = false;
  constructor(props: IPropsWithClasses<IProps>) {
    super(props);
    this.state = {
      activeLog: [],
      logs: [],
      displayedLogs: [],
      logStatus: DATA_INIT,
      status: DATA_OK,
      start: null,
      worked: null,
      confirmClose: null,
      clockState: "E",
      pauses: [],
      errors: {},
    };
    this.updateDisplayedTime = this.updateDisplayedTime.bind(this);
  }
  async componentDidMount() {
    this.MOUNTED = true;
    await this.load();
    this.getCurrentState();
  }
  componentWillUnmount() {
    this.MOUNTED = false;
  }
  getCurrentState() {
    const start = this.state.logs.find((log: ILog) => {
      const isBeginning = JSON.parse(log?.body || "{}").name === "A";
      return log.objectName === "timeclock" && isBeginning;
    });
    const hasEnded = Boolean(
      this.state.logs.find((log: ILog) => {
        const isEnd = JSON.parse(log?.body || "{}").name === "E";
        const isCorrespondingEnd =
          !start ||
          +new Date(log.createdAt || "") > +new Date(start.createdAt || "");
        return log.objectName === "timeclock" && isEnd && isCorrespondingEnd;
      })
    );
    if (!start || hasEnded) return;
    const startTime = Math.floor(+new Date(start.createdAt || "") / 1000);
    const lastState = this.state.logs.find((log: ILog) => {
      return log.objectName === "timeclock";
    });
    const lastStateType: "A" | "E" | "PA" | "PE" = JSON.parse(
      lastState?.body || "{}"
    ).name;
    const started =
      lastStateType === "E" ? null : +new Date(start.createdAt || "");
    const worked =
      lastStateType !== "E" && started !== null
        ? Math.floor((+new Date() - started) / 1000)
        : null;

    const newState: IState = {
      ...this.state,
      start: started,
      worked,
      clockState:
        lastStateType === "PA"
          ? "P"
          : lastStateType === "PE"
          ? "A"
          : lastStateType,
    };

    //fetch pauses
    if (lastStateType === "PA" || lastStateType === "PE") {
      const pausesStarts = this.state.logs.filter((log: ILog) => {
        const typeName = JSON.parse(log?.body || "{}").name;
        const createdAt = Math.floor(+new Date(log.createdAt || "") / 1000);
        return typeName === "PA" && createdAt > startTime;
      });
      const pausesEnds = this.state.logs.filter((log: ILog) => {
        const typeName = JSON.parse(log?.body || "{}").name;
        const createdAt = Math.floor(+new Date(log.createdAt || "") / 1000);
        return typeName === "PE" && createdAt > startTime;
      });

      const pauses: IState["pauses"] = pausesStarts.map((item) => {
        const createdAt = Math.floor(+new Date(item.createdAt || "") / 1000);
        return { start: createdAt, end: null };
      });

      pausesEnds.forEach((item, index) => {
        const createdAt = Math.floor(+new Date(item.createdAt || "") / 1000);
        let pausesIndex = index;
        if (pausesStarts.length > pausesEnds.length) pausesIndex++;
        pauses[pausesIndex].end = createdAt;
      });
      newState.pauses = pauses;
    }

    this.setState(newState);
    setTimeout(() => this.updateDisplayedTime(), 1000);
  }
  updateDisplayedTime() {
    if (this.MOUNTED) {
      const newState = {
        ...this.state,
      };
      if (newState.worked !== null && newState.start !== null) {
        newState.worked = Math.floor((+new Date() - newState.start) / 1000);
        if (this.MOUNTED)
          this.setState(newState, () =>
            setTimeout(this.updateDisplayedTime, 1000)
          );
      }
    }
  }
  async load() {
    if (this.state.logStatus !== DATA_INIT)
      this.setState({ ...this.state, logStatus: DATA_PENDING });
    try {
      const result = await Log.get(
        {
          usagePlan: this.props.user.usagePlan,
          object: this.props.user.id,
        },
        ["id", "object", "objectName", "body", "method", "createdAt"],
        null,
        50
      );

      const start = [...result.data].find((log: ILog) => {
        const isBeginning = JSON.parse(log?.body || "{}").name === "A";
        return log.objectName === "timeclock" && isBeginning;
      });

      const activeLog = start
        ? [...result.data].filter((item) => {
            return (
              item.createdAt &&
              start.createdAt &&
              item.createdAt >= start.createdAt
            );
          })
        : [];

      const displayedLogs = [...result.data]
        .reverse()
        .reduce((accu: IDisplayedLogs[], log: ILog) => {
          const type = JSON.parse(log.body || "{}").name;

          if (type === "A")
            return [
              {
                start: log.createdAt || null,
                end: null,
                pauses: [],
              },
              ...accu,
            ];
          else if (type === "E" && accu.length > 0)
            accu[0].end = log.createdAt || null;
          else if (type === "PA" && accu.length > 0)
            accu[0].pauses = [
              { start: log.createdAt || "", end: null },
              ...accu[0].pauses,
            ];
          else if (
            type === "PE" &&
            accu.length > 0 &&
            accu[0].pauses.length > 0
          )
            accu[0].pauses[0].end = log.createdAt || null;
          return accu;
        }, []);
      this.setState({
        ...this.state,
        logStatus: DATA_OK,
        logs: result.data,
        displayedLogs,
        activeLog,
      });
    } catch (error) {
      this.setState({ ...this.state, logStatus: DATA_ERROR });
    }
  }
  async timer(method: "start" | "startPause" | "endPause" | "end") {
    this.setState({ ...this.state, status: DATA_PENDING });
    try {
      await TimeclockLib[method](this.props.user.usagePlan);
      this.setState({
        ...this.state,
        logs: [],
        logStatus: DATA_INIT,
        start: null,
        worked: null,
        pauses: [],
        errors: {},
        status: DATA_OK,
        clockState: method === "start" ? "A" : method === "end" ? "E" : "P",
      });
      await this.load();
      this.getCurrentState();
    } catch (error) {
      this.setState({ ...this.state, status: DATA_ERROR });
    }
  }
  render() {
    const { classes } = this.props;

    const worked = this.state.pauses.reduce((accu, pause) => {
      const now = Math.round(+new Date() / 1000);
      return accu - ((pause.end || now) - pause.start);
    }, this.state.worked || 0);

    const houres = Math.floor((worked || 0) / 3600);
    const mins = Math.floor(((worked || 0) % 3600) / 60);
    const seconds = Math.floor((worked || 0) % 60);

    return (
      <Grid
        justify="center"
        spacing={16}
        vertical
        root={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <Grid item xs="304px">
          <Block>
            <StateHandler
              status={
                this.state.logStatus === DATA_INIT
                  ? DATA_PENDING
                  : this.state.status
              }
              noSuccess={true}
              errors={this.state.errors}
            >
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                spacing={8}
              >
                <Grid
                  justify="center"
                  spacing={8}
                  vertical
                  root={{ bottom: 0, right: 0, left: 0 }}
                >
                  <Grid item>
                    <div
                      className={classNames([
                        classes.display,
                        this.state.clockState === "P" && classes.pause,
                      ])}
                    >
                      {houres < 10 ? "0" : ""}
                      {houres}:{mins < 10 ? "0" : ""}
                      {mins}:{seconds < 10 ? "0" : ""}
                      {seconds}
                    </div>
                  </Grid>
                  {this.state.clockState !== "A" && (
                    <Button
                      item
                      xs="50px"
                      primary
                      onClick={(e) => {
                        if (this.state.clockState === "P")
                          this.timer("endPause");
                        else this.timer("start");
                      }}
                    >
                      <FontAwesomeIcon icon={faPlay} />
                    </Button>
                  )}
                  {this.state.clockState !== "P" &&
                    this.state.clockState !== "E" && (
                      <Button
                        item
                        xs="50px"
                        warning
                        onClick={(e) => {
                          this.timer("startPause");
                        }}
                      >
                        <FontAwesomeIcon icon={faPause} />
                      </Button>
                    )}
                  {this.state.clockState !== "E" &&
                    this.state.clockState !== "P" && (
                      <Button
                        item
                        xs="50px"
                        danger
                        onClick={(e) => {
                          this.timer("end");
                        }}
                      >
                        <FontAwesomeIcon icon={faStop} />
                      </Button>
                    )}
                </Grid>
              </Form>
            </StateHandler>
          </Block>
        </Grid>
        <Grid item xs="304px">
          <Tasklog
            enabled={this.state.clockState === "A"}
            user={this.props.user}
          />
        </Grid>
        <Grid item xs="304px">
          <LogComp
            activeLog={this.state.activeLog}
            logs={this.state.displayedLogs}
            status={this.state.logStatus}
            errors={this.state.errors}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withClasses(styles, Timeclock);
