import React from "react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

interface IBlock {
  padding?: string | number;
  children: any;
}

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  block: {
    width: "100%",
    height: "100%",
    borderRadius: "0px",
    backgroundColor: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 0px",
    padding: (props: IBlock) =>
      typeof props.padding !== "undefined" ? props.padding : 16,
    boxSizing: "border-box",
  },
});

const Block: React.FC<IBlock> = (props) => {
  const classes = buildClasses(styles, props);
  return <div className={classes.block}>{props.children}</div>;
};

export default Block;
